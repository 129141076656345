import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default () => {
  return (
    <Layout>
      <section className="hero">
        <div className="hero-body">
          <div className="container box">
            <h1 className="title">お問い合わせありがとうございました</h1>
            <p className="hax-text-centered">
              このたびは、お問合せいただき、誠にありがとうございました。
              <br />
              お送りいただきました内容を確認の上、担当者より折り返しご連絡させていただきます。
            </p>
          </div>
        </div>
      </section>
      <section className="section pt-0">
        <div className="container">
          <Link to="/">前のページに戻る</Link>
        </div>
      </section>
    </Layout>
  )
}
